<template>
    <div class="amDiv">
        <el-radio-group v-model="type" @change="radioChange">
        <el-radio-button v-for="(item,i) in typeList" :key="i"  :label="item"></el-radio-button>
        </el-radio-group>
        <div >
            <h4>{{this.obj.name}}</h4>
            <h5>岗位描述：</h5>
            <p class="amTextDiv" v-html="obj.desc">
               
            </p>
            <h5>任职要求：</h5>
            <p class="amTextDiv" v-html="obj.duty">
              
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name:"aboutMember",
    data(){
        return{
            type:"销售",
            List:[],
            obj:{},
            typeList:["销售","电话客服","Java开发工程师","Python开发工程师","前端开发工程师","测试工程师"]
        }
    },
    methods:{
        init(){
            let dataList=[
                {
                    name:"销售",
                    desc:`
                    1.  根据公司制定的销售目标完成对应的销售业绩；
                    <br/>2.  找寻目标客户，了解客户需求，制定对应销售策略；
                    <br/>3.  维护客户关系，参与客户业务谈判。
                    `,
                    duty:`
                    1.  大专以上学历，有销售经验者优先；
                    <br/>2.  良好的沟通、表达、应变能力，思维逻辑清晰；
                    <br/>3.  有激情、乐观向上、积极主动，且善于学习，敢于挑战自我。
                    `
                },
                {
                    name:"测试工程师",
                    desc:`
                    1.  负责公司产品的测试；
                    <br/>2.  参与需求评审，根据产品需求制定测试计划；
                    <br/>3.  编写测试用例；
                    <br/>4.  对bug进行跟踪、回归测试，完成项目验收测试；
                    <br/>5.  及时反馈追踪重大缺陷，推进缺陷解决进度；
                    <br/>6.  编写测试报告；
                    <br/>7.  协助产品经理进行产品设计及管理；
                    <br/>8.  其他临时性任务。
                    `,
                    duty:`
                    1.  大专以上学历；
                    <br/>2.  熟练掌握办公应用软件；
                    <br/>3.  熟悉至少一种数据库（mysql、sqlserver、mongo等）；
                    <br/>4.  熟悉至少一种自动化测试工具（postman、jmeter、loadrunner等）；
                    <br/>5.  熟悉linux命令；
                    <br/>6.  了解axure rp、xmind、navicat、svn 等工具；
                    <br/>7.  有耐心，工作主动性强，善于发现问题，定位缺陷；
                    <br/>8.  具备良好的沟通能力，团队合作精神。
                    `
                },
                {
                    name:"Python开发工程师",
                    desc:`
                    1.  根据开发规范和流程独立完成核心模块的设计和编码，负责所辖系统的线上维护工作；
                    <br/>2.  业务平台、数据平台开发；
                    <br/>3.  根据业务、产品和项目相关需求，负责数据验证、数据管理、数据服务接口、数据分析等功能的开发维护工作；
                    <br/>4.  参与数据平台数据分析模块的开发。
                    `,
                    duty:`
                    1.  全日制统招本科及以上学历，计算机相关专业，3年以上相关工作经验；
                    <br/>2.  精通Python，熟练使用一种WEB框架，例如django、 webpy、 tornado；
                    <br/>3.  熟悉linux系统，熟悉运维开发；
                    <br/>4.  能够熟练使用Redis、Kafka等进行数据处理开发和维护工作，并有MQ、NoSQL实战经验；
                    <br/>5.  熟悉mysql等关系数据库，熟练使用mongodb等nosql数据库；
                    <br/>6.  熟练使用搜索引擎工具（ elasticsearch 等）；
                    <br/>7.  熟练使用 numpy、pandas、scipy 等科学计算工具；
                    <br/>8.  熟悉python相关excel开发 ；
                    <br/>8.  具备良好的编程习惯和逻辑思维能力；
                    <br/>9.  具备创业心态，善于学习，热爱技术开发，善于团队协作，能积极主动地参与公司产品研发等相关工作。
                    `   
                },
                {
                    name:"Java开发工程师",
                    desc:`
                    1.  参与需求评审、系统概要设计，并负责完成核心代码；
                    <br/>2.  根据开发规范和流程独立完成核心模块的设计和编码；
                    <br/>3.  负责所辖系统的线上维护工作；
                    <br/>4.  完成模块单元测试代码编写；
                    <br/>5.  技术分享，新技术研究。
                    `,
                    duty:`
                    1.  本科以上学历，计算机及相关专业； 
                    <br/>2.  Java 基础扎实，熟练使用常见设计模式，熟悉常用算法和 JVM 工作原理； 
                    <br/>3.  熟练使用 Spring、SpringBoot、SpringCloud 等架构，了解其工作原理；
                    <br/>4.  熟悉微服务、异步框架、集群与负载均衡、消息中间件等技术；
                    <br/>5.  熟练使用 mysql、mongo 等数据库技术，能够编写并优化复杂 SQL；
                    <br/>6.  热爱学习，工作主动性强，具有团队合作精神和责任心； 
                    <br/>7.  熟悉微服务、异步框架、集群与负载均衡、消息中间件等技术；
                    <br/>8.  熟悉NoSQL( mongodb、Redis、Memcached )等存储结构原理及分布式存储一致性解决方案；
                    <br/>9.  熟悉其他语言（如Python）尤佳。
                    `
                },
                {
                    name:"前端开发工程师",
                    desc:`
                    1.  开发产品中各种图形、动画等交互功能，提供合理的前端架构；
                    <br/>2.  根据UI设计师提供的设计图，利用HTML、CSS、JavaScript等技术实现，优化代码并保持各客户端下的良好兼容性；
                    <br/>3.  讨论用户体验和细化需求，根据业务需求，设计并实现技术方案；
                    <br/>4.  与后端深度沟通，达成一致技术接口实现方案。
                    `,
                    duty:`
                    1.  本科及以上学历，工作经验3年以上；
                    <br/>2.  精通HTML(5) 、CSS(3) ，精通至少一种JS框架( react、vue、angular )或者对JS语言掌握较深；
                    <br/>3.  能轻松写出符合W3C标准、兼容主流浏览器的代码；
                    <br/>4.  精通HTML、HTML5、CSS3、Javascript、Ajax页面模板等前端技术，能编写兼容多种客户端代码；
                    <br/>5.  掌握至少一门非前端开发语言为加分项（如 PHP、Java、Python、Ruby 等），并有一定的实战经验。
                    `
                },
                {
                    name:"电话客服",
                    desc:`
                    1.  通过电话等渠道收集客户信息，发掘新客户，并做记录；
                    <br/>2.  通过电话形式与客户进行有效沟通，了解客户需求，找寻合作机会；
                    <br/>3.  协助市场部完善客户信息。
                    `,
                    duty:`
                    1.  大专以上学历，有电话客服经验者优先；
                    <br/>2.  良好的沟通、表达、应变能力，思维逻辑清晰；
                    <br/>3.  喜欢电话客服工作，且抗压能力强。
                    `
                }
            ]
            this.List=dataList
            this.obj=dataList[0]
        },
        radioChange(val){
            for(let i=0;i<this.List.length;i++){
                let item=this.List[i]
                if(item.name==val){
                    this.obj=item
                    break
                }
            }
        }
    },
    created(){
        this.init()
    }
    
}
</script>
<style>
.amDiv{
    padding:60px 0
}
.amDiv h4{
    font-size: 18px;
    color: #404756;
    margin-top: 60px;
}
.amDiv h5{
    font-size: 16px;
    color: #404756;
    margin-top: 30px;
}
.amDiv .amTextDiv{
    font-size: 14px;
    color: #646464;
    line-height: 26px;
}
</style>